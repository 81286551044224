<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dateModel"
    transition="scale-transition"
    min-width="auto"
    offset-y
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-text-field
        v-model="formattedDate"
        v-bind="attrs"
        v-on="on"
        class="pt-0 mt-0"
        :label="label"
        :disabled="disabled"
        :hide-details="hideDetails"
        :rules="rules"
        prepend-icon="mdi-calendar-blank"
        readonly
      />
    </template>
    <div
      class="d-flex flex-column align-center"
      style="background-color: white;"
    >
      <date-picker
        v-model="dateModel"
        :type="type"
        use12h
        :show-second="false"
        :minute-step="5"
        :time-title-format="'MMMM D, YYYY'"
        inline
      />
      <div
        class="d-flex align-center justify-end my-2"
        style="width: 100%;"
      >
        <v-btn
          @click="menu = false"
          class="mr-1"
          depressed
        >
          Cancel
        </v-btn>
        <v-btn
          @click="$refs.menu.save(dateModel)"
          class="mr-2"
          color="primary"
          depressed
        >
          OK
        </v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "@/assets/datepicker.scss";

import moment from "moment"

export default {
  name: "AppDatePicker",

  components: {
    DatePicker,
  },

  props: {
    label: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: false, // Would be required, but it's ok for this to start as null.
      default: null,
    },
    min: {
      type: String,
      required: false,
      default: null
    },
    max: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      required: false,
      default: "auto",
    },
    rules: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    type: {
      type: String,
      required: false,
      default: "date",
    }
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    dateModel: {
      get() {
        return this.date
      },
      set(newDate) {
        this.$emit('updateDate', newDate)
      }
    },

    formattedDate() {
      return this.date
        ? this.type == "datetime"
          ? moment(this.date).format("MM/D/YYYY, hh:mm A")
          : moment(this.date).format("MM/D/YYYY")
        : ""
    },
  }
}
</script>